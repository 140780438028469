import React from "react";
import "./Work.scss";
import Panel from "../../Layout/Panel";

import Image from "../../../Content/Offspace/Demo-Next-Level-04.png"

export default function Work() {
  return (
    <Panel id="work" className="work">
      <div className="grid">
        <div className="cell">
          <div className="card">
            <img src={Image} alt="" />
            <div>
              <h1>Title</h1>
              <p>Info</p>
              <a href="/work/offspace">Link</a>
            </div>
          </div>
        </div>
        <div className="cell">
          <div className="card">
          <img src={Image} alt="" />
            <div>
              <h1>Title</h1>
              <p>Info</p>
              <a href="/work/offspace">Link</a>
            </div>
          </div>
        </div>
        <div className="cell">
          <div className="card">
          <img src={Image} alt="" />
            <div>
              <h1>Title</h1>
              <p>Info</p>
              <a href="/work/offspace">Link</a>
            </div>
          </div>
        </div>
        <div className="cell">
          <div className="card">
          <img src={Image} alt="" />
            <div>
              <h1>Title</h1>
              <p>Info</p>
              <a href="/work/offspace">Link</a>
            </div>
          </div>
        </div>
      </div>
    </Panel>
  );
}