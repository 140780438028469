import React, { useEffect, useState } from "react";
import { useLocation } from "wouter";
import "./NavButton.css";

export default function NavButton({route, className, classNameActive, onClick, children}) {

  const [location, setLocation] = useLocation();

  const [active, setActive] = useState(false);

  const handleOnClick = (e) => {
    e.stopPropagation();
    setLocation(route,{replace:false});
    if(onClick){
      onClick();
    }
  }

  useEffect(() => {
    const getId = (str) => {
      const result = str.replace(/[/#]/g, "");
      return result;
    };
    const onHashChange = () => {
      setActive(getId(window.location.hash) === getId(route));
    };
    window.addEventListener("hashchange", onHashChange);
    window.addEventListener("locationchange", onHashChange);
    onHashChange();
    return () => {
      window.removeEventListener("hashchange", onHashChange);
      window.removeEventListener("locationchange", onHashChange);
    };
  }, []);

  const styles = {
    base:  className ? className : "nav-button",
    active: classNameActive ? classNameActive : "nav-button-active"
  }

  return (
    <button onClick={handleOnClick} className={`${styles.base} ${active ? styles.active : ""}`}>{children}</button>
  );
}
