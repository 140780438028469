import React from "react";
import "./WorkOffspace.css";
import Page from "../../Layout/Page";
import Section from "../../Layout/Section";

export default function WorkOffspace() {
  return (
    <Page>
      <Section className="offspace-hero">
        Hello
      </Section>
      <Section className="offspace-panel">
        World!
      </Section>
    </Page>
  );
}