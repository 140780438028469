import React, { useState, useEffect } from "react";
import "./NavBar.css";
import { ReactComponent as Logo } from "../../Assets/Images/ak-logo.svg";
import NavButton from "../UI/NavButton";
import NavMenuButton from "../UI/NavMenuButton";

export default function NavBar() {
  const [active, setActive] = useState(false);

  const toggleActive = () => {
    setActive(!active);
  }

  const onClose = () => {
    setActive(false);
  };

  const onResize = () => {
    const isDesktop = matchMedia("(min-width: 768px)").matches;
    if(isDesktop) setActive(false);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <header className={`nav-header${active ? " nav-active" : ""}`}>
      <div className="nav-bar">
        <NavButton className="nav-logo-button" route="/" onClick={onClose}>
          <Logo />
        </NavButton>
        <NavMenuButton isActive={active} onClick={toggleActive} />
      </div>
      <nav className="nav-menu">
        <div className="nav-menu-item">
          <NavButton route="/#about" onClick={onClose}>About</NavButton>
        </div>
        <div className="nav-menu-item">
          <NavButton route="/#work" onClick={onClose}>Work</NavButton>
        </div>
        <div className="nav-menu-item">
          <NavButton route="/#contact" onClick={onClose}>Contact</NavButton>
        </div>
          
      </nav>
    </header>
  );
}
