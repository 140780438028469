import React from "react";
import "./Hero.css";
import Panel from "../../Layout/Panel";
import Foto from "../../../Assets/Images/foto.jpg"

export default function Hero({ children }) {
  return (
    <Panel id="hero" className="hero">
      <img className="hero-image" src={Foto} />
      <div className="hero-title">
        <h1>Arne Kossmann</h1>
        <h2>Portfolio 2024</h2>
      </div>
    </Panel>
  );
}