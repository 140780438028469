import React from "react";
import "./NavMenuButton.scss";

export default function NavMenuButton({isActive, onClick}) {

  const handleOnClick = (e) => {
    e.stopPropagation();
    onClick();
  }

  return (
    <button onClick={handleOnClick} className={`nav-menu-button${isActive ? " active" : ""}`}>
      <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g>
          <line x1="8" y1="16" x2="40" y2="16" strokeWidth="2" stroke="currentColor" />
          <line x1="8" y1="24" x2="40" y2="24" strokeWidth="2" stroke="currentColor" />
          <line x1="8" y1="32" x2="40" y2="32" strokeWidth="2" stroke="currentColor" />
        </g>
        
        <g>
          <line x1="8" y1="24" x2="40" y2="24" strokeWidth="2" stroke="currentColor" />
          <line x1="8" y1="24" x2="40" y2="24" strokeWidth="2" stroke="currentColor" />
        </g>
      </svg>
    </button>
  );
}
