import React from "react";
import "./About.css";
import Panel from "../../Layout/Panel";

export default function About({ children }) {
  return (
    <Panel id="about" className="about">
      {children}
    </Panel>
  );
}