import React from 'react';
import { Switch, Route, Redirect} from "wouter";
import HomePage from "./Components/Pages/Home/HomePage";

import WorkOffspace from './Components/Pages/Work/WorkOffspace';

function App() {
  return (
    <Switch>
      <Route path="/">
        <HomePage />
      </Route>

      <Route path="/work/offspace">
        <WorkOffspace />
      </Route>

      {/* Default route in a switch */}
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
    
  );
}

export default App;
