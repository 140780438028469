import React from "react";
import "./HomePage.css";
import OnePage from "../../Layout/OnePage";
import Hero from "./Hero";
import About from "./About";
import Work from "./Work";
import Contact from "./Contact";

export default function HomePage() {
  return (
    <OnePage>
      <Hero />
      <About />
      <Work />
      <Contact />
    </OnePage>
  );
}