import React, {useState, useEffect} from "react";
import "./Contact.scss";
import Panel from "../../Layout/Panel";

export default function Contact() {

  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});

  const [formState, setFormState] = useState("send");

  const validateInput = (name, type, value) => {
    const maxLength = type === "textarea" ? 1024 : 256;
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/;
    let message = "";

    if(value){
      if(value.length >= 3){
        if(value.length <= maxLength){
          if(type === "email"){
            const match = value.match(emailRegex);
            if(!match){
              message = "No Email";
            }
          }
        }else{
          message = "Too Long";
        }
      }else{
        message = "Too Short";
      }
    }else{
      message = "Empty";
    }
    setErrors(values => ({...values, [name]: message}))
    return !message;
  }

  const validateForm = (form) => {
    const inputNodes = form.querySelectorAll("input, textarea");
    let isValid = true;
    inputNodes.forEach((node) => {
      const name = node.name;
      const type = node.type;
      const value = node.value;
      const result = validateInput(name, type, value);
      if(!result) isValid = result;
    });
    return isValid;
  }

  const sendForm = async (form) => {
    const formData = new FormData(form);
    formData.append("valid", "");

    try {
      const response = await fetch("https://ak76.net/send/", {
        method: "POST",
        body: formData,
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      });
      return response.json();
    }catch(e){
      return e;
    }
  }

  const resetForm = (form) => {
    console.log("Reset Form");
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const type = event.target.type;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}))
    if(errors[name]){
      validateInput(name, type, value);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target.form;

    if(validateForm(form)){
      setFormState("sending...");
      sendForm(form).then(response => {
        if(response.status){
          setFormState("succeeded");
        }else{
          setFormState("failed");
        }
        setTimeout(() => {
          if(formState === "succeeded") resetForm();
          setFormState("send");
        }, 1000);
      });
    }
  }

  const handleUpdate = (event) => {
    const name = event.target.name;
    const type = event.target.type;
    const value = event.target.value;
    validateInput(name, type, value);
  }

  useEffect(() => {
    window.addEventListener("change", handleUpdate);
    return () => {
      window.removeEventListener("change", handleUpdate);
    };
  });

  const isBusy = formState !== "send";

  return (
    <Panel id="contact" className="contact">
      <div className="contact-form">
        <form>
            <div className="item">
              <label htmlFor="name">NAME</label>
              <input 
                type="text" 
                id="name" 
                name="name" 
                placeholder="Name"
                value={inputs.name || ""} 
                onChange={handleChange}
                disabled={isBusy}
              />
              {errors.name && (
                <span className="error">{`ERROR: ${errors.name}`}</span>
              )}
            </div>

            <div className="item">
              <label htmlFor="email">EMAIL</label>
              <input 
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={inputs.email || ""} 
                onChange={handleChange}
                disabled={isBusy}
              />
              {errors.email && (
                <span className="error">{`ERROR: ${errors.email}`}</span>
              )}
            </div>

            <div className="item">
              <label htmlFor="message">MESSAGE</label>
              <textarea
                id="message"
                name="message"
                placeholder="Message"
                value={inputs.message || ""}
                onChange={handleChange}
                disabled={isBusy}
              />
              {errors.message && (
                <span className="error">{`ERROR: ${errors.message}`}</span>
              )}
            </div>

            <div className="item">
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={isBusy}>{formState}</button>
            </div>
        </form>
      </div>
      
    </Panel>
  );
}