import React, { useRef, useEffect }  from "react";
import { useLocation } from "wouter";
import "./Panel.scss";

export default function Panel({ id, className, children }) {
  const panelRef = useRef();

  const [location, setLocation] = useLocation();

  useEffect(() => {
    const getId = (str) => {
      const result = str.replace(/[/#]/g, "");
      return result;
    };
    const onScrollEnd = () => {
      const isStartPanel = panelRef.current.offsetTop == 0;
      const isPanelHash = getId(window.location.hash) === !isStartPanel ? id : "";
      // current.innerHeight = better!?
      const isInView = panelRef.current.offsetTop / window.innerHeight === Math.floor(window.scrollY / window.innerHeight);
      if(!isPanelHash && isInView){
        setLocation(!isStartPanel ? `/#${id}` : "/",{replace:true});
      }
    };
    window.addEventListener("scrollend", onScrollEnd);
    return () => {
      window.removeEventListener("scrollend", onScrollEnd);
    };
  }, []);
  return (
    <div ref={panelRef} id={id} className={`panel ${className ? className : ''}`}>
      {children}
    </div>
  );
}